/*eslint no-unused-vars: "off"*/
import React, { useReducer, useEffect, useContext, useState } from 'react';
//import { BrowserRouter as Router, Route,withRouter } from "react-router-dom";
import Views from "./Views";
import Loading from "./Loading";
import Rename from "./Rename";
import UserLogin from "./UserLogin";
import OAuth from './services/OAuth';
import Error from "./Error";
import Toasts from "./Toasts";
import Statusbar from "./Statusbar";
import { StateContext, DispatchContext } from "./Contexts";
import { Keyboard, triggerKey } from "./Keyboard";
import processQueryString from './processQueryString'
//import Icon from './icons.js'
//import Modal from "./Modal";
import { setDateFormat, setFormatDateString, setFormatDateTimeString, setFormatTimeString } from './format';
import { globals } from './globals';
import Actionmenu from './Actionmenu'
import { uuidv4 } from './functions'
import screenshot from "./screenshot";

globals.authserver1 = "https://id.tracy.nu";
globals.authserver2 = "https://id2.tracy.nu";

globals.isLoading = false;
globals.keysDuringLoading = [];
//import useKeyboardShortcut from './useKeyboardShortcut'
export default function App() {
	const [start, setStart] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState(false);
	if (redirectUrl) return <>
		<div class="text-center text-muted display-2 mt-4">302</div>
		<div class="text-center h4 text-muted font-weight-light m-3 p-4">The application has moved to <a href={redirectUrl}>this location</a>.</div>
	</>
	if (!start) {
		if (window.staticApiBaseUrl) {
			initialState.base = window.staticApiBaseUrl;
			setStart(true);
		} else {

			fetch('https://apis.tsql.app/v1/domains?hostname=' + window.location.host
				, { headers: { ApiKey: 'aoapIornAogVIaASUonOyXUIoopSZOeYAc0mZiNZoDafarosADyEkAOIgeyaouuaaOa1aIoNosAIhouOeJdzAEO1e4EkUYMY0l' } }, 1000)
				.then(res => res.json())
				.then(json => {
					if (json.data && json.data.length) {
						if (json.data[0].redirect_hostname) {
							let url = '//' + json.data[0].redirect_hostname + window.location.pathname + window.location.search
							setRedirectUrl(url);
							//window.open(url, '_blank', null);
							//window.location.replace(url);
							//throw 'Redirecting!'
						} else if (json.data[0].api) {
							let api_base = json.data[0].api;
							initialState.base = api_base;
							document.cookie = "domains_api_base=" + api_base + "; domain =." + window.location.hostname + ";path=/;max-age=" + (3600 * 24 * 30).toString();
						}
					}
				})
				.catch(res => {
					//console.log('err', res)
					let regex = new RegExp('(?:(?:^|.*;\\s*)' + "domains_api_base" + '\\s*\\=\\s*([^;]*).*$)|^.*$');
					let api_base = document.cookie.replace(regex, "$1");
					if (api_base) {
						initialState.base = api_base;
						console.log('From cookie:', api_base);
					} else {
						console.log('err', res)
					}
				}
				)
				.finally(res => { if (initialState.base) setStart(true) })
		}
		return '';
	} else {
		return (
			<Keyboard>
				<InnerApp />
			</Keyboard>
		);
	}
}

function remDupObjects(arr) {
	return arr.filter((thing, index) => {
		return index === arr.findIndex(obj => {
			return JSON.stringify(obj) === JSON.stringify(thing);
		});
	});
}

function InnerApp() {
	const [state, dispatch] = useReducer(mainReducer, initialState);
	useEffect(() => {
		globals.dispatch = dispatch;
		window.onpopstate = function (event) {
			//console.log(event);
			dispatch({ type: 'pop_state', state: event.state });
		};
	}, []);
	return (
		<DispatchContext.Provider value={dispatch}>
			<StateContext.Provider value={state}>
				<Main />
				<Loading />
			</StateContext.Provider>
		</DispatchContext.Provider>
	);
}

function Main() {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	//useKeyboardShortcut(['ArrowDown'], () => console.log('Shift + H has been pressed.'))
	if (!state.bearer) return <UserLogin />
	return (<><style>{state.settings.customStyle ? state.settings.customStyle : ''}</style>
		{state.settings.nightmode ? <style>{state.settings.nightmode === "1" ? "html{filter: brightness(.6) contrast(1.5) !important;}" : state.settings.nightmode}</style> : ''}
		<div className={"container wrapper" + (state.dragging ? ' dragging' : '') + (state.edit !== null ? ' edit' : '') + ' ' + (state.settings.containerClass ? state.settings.containerClass : '')}
		/*
		onDragEnter={() => { if (!state.dragging) dispatch({ type: 'drag_enter' }) }}
		onDragLeave={() => { if (state.dragging) dispatch({ type: 'drag_leave' }) }}
		onDrop={(evt) => {
			if (state.dragging) dispatch({ type: 'drag_leave' })
			evt.preventDefault();
			evt.stopPropagation();
		}}
		onDragOver={(evt) => {
			if (!state.dragging) dispatch({ type: 'drag_enter' })
			evt.preventDefault();
			evt.stopPropagation();

		}}
		*/
		>
			<div className={"shadow-wrapper"}></div>
			{/*<h3> Lets go for a <Icon name="IoMdFingerPrint" /> <Icon name="FaSpider" /></h3>*/}
			<Error />
			{state.toasts ? <Toasts toasts={state.toasts} edit={state.edit !== null} /> : ''}
			{state.json && state.json.actions ? <Actionmenu /> : ''}
			{state ? <Views /> : ''}
			{state.json && state.json.statusbar ? <Statusbar state={state} data={state.json.statusbar} /> : ''}
			{state.settings && state.settings.is_designer === "1" && state.rename && state.rename.name ? <Rename /> : ''}
		</div>
	</>
	);
}
function getNewToken() {
	let auth = new OAuth({ server: globals.authserver1 });
	//console.log('getNewToken()')
	//	auth.refresh().then(token => {
	auth.getToken().then(token => {
		if (token) {
			let expiresTime = auth.getCookie("expires")
			//expiresTime = Math.round((new Date()).getTime() / 1000)+10
			let expiresInSeconds = expiresTime - Math.round((new Date()).getTime() / 1000) - Math.round(Math.random() * 60)
			//console.log('Minutes', expiresInSeconds / 60)
			//expiresInSeconds = 10;console.log('expiresInSeconds TEST!!!!! Should not be seen in production!!!!')
			setTimeout(getNewToken, expiresInSeconds * 1000);
			globals.bearerExpires = expiresTime;
			globals.bearer = token;
			//console.log('globals.bearer')
		}
		globals.dispatch({ type: "user_token", token }); //GB210118
	});
}
/*
function checkToken(state, action) {
	if (action.type!== 'user_token' && globals.lastAction !== action && globals.bearerExpires && globals.bearerExpires <= Math.round((new Date()).getTime() / 1000)) {
		globals.lastAction = action
		let auth = new OAuth({ server: "https://id.tracy.nu" });
		auth.getToken().then(token => {
			if(token){
				let expiresTime = auth.getCookie("expires")
				expiresTime = Math.round((new Date()).getTime() / 1000)+10
				globals.bearerExpires = expiresTime;
				globals.bearer = token;
				console.log('globals',token)
				globals.dispatch({type:"user_token",token});
			}
			globals.dispatch(action);
		});
		return false
	} else {
		return false
	}
}
*/
function mainReducer(state, action) {
	/*	if (!globals.isLoading && globals.keysDuringLoading.length > 0) {
			let daKey = globals.keysDuringLoading.pop()
			setTimeout(() => triggerKey(daKey), 500);
		}
	*/	//globals.randomString = Math.random().toString(36).substring(7);
	//if (checkToken(state, action)) return state;
	//globals.prev_pathname = state.pathname
	//globals.prev_search = globals.selectedId!==undefined&&state.settings.is_list && state.settings.is_list === "1"?processQueryString(state.search,{id:globals.selectedId}):state.search;
	//console.log(state.search);
	//console.log(state.json);

	//gb240214 
	if (state.base) window.history.replaceState(state, '', state.pathname + state.search);

	//console.log('dispatch: ' + action.type,state.isLoading,globals.isLoading);

	var search, newstate, lidx, selectedIds, selectedIdsByPath, hasPicklist, pathname, origValues;
	let { cardChanges, cardChangesCounter, settings } = state;
	cardChangesCounter++;
	switch (action.type) {
		case 'field': {
			return {
				...state,
				[action.fieldName]: action.payload,
			};
		}
		case 'user_token': {
			return {
				...state,
				bearer: action.token,
				//bearerExpires: action.expiresTime,
			}
		}
		case 'user_authenticated': {
			let { user, expiresTime } = action;
			//expiresTime = Math.round((new Date()).getTime() / 1000) + 10
			globals.bearerExpires = expiresTime;
			globals.bearer = user.token;
			//globals.user = user
			//const { timeout } = action;
			//const { expires } = action;
			let expiresInSeconds = expiresTime - Math.round((new Date()).getTime() / 1000) - Math.round(Math.random() * 60)
			//console.log('Minutes', expiresInSeconds / 60)
			//expiresInSeconds = 10;console.log('expiresInSeconds TEST!!!!! Should not be seen in production!!!!')
			setTimeout(getNewToken, expiresInSeconds * 1000);
			//setTimeout(timeout, 10 * 1000);
			//console.log('expiresTime',expiresTime,Math.round((new Date()).getTime() / 1000));
			return {
				...state,
				toasts: [],
				bearer: user.token,
				//bearerExpires: expiresTime,
				//bearerGetToken: user.getToken,
			};
		}
		case 'success': {
			return {
				...state,
				isLoggedIn: true,
				isLoading: false,
			};
		}
		case 'error': {
			globals.isLoading = false;
			return {
				...state,
				error: action.message,
				isLoading: false,
			};
		}
		case 'logOut': {
			document.cookie = "oauth_refreshToken=;domain=.tracy.nu;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_token=;domain=.tracy.nu;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_expires=;domain=.tracy.nu;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_uid=;domain=.tracy.nu;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_refreshToken=;domain=." + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_token=;domain=." + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_expires=;domain=." + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_uid=;domain=." + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_refreshToken=;domain=" + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_token=;domain=" + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_expires=;domain=" + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			document.cookie = "oauth_uid=;domain=" + window.location.hostname + ";expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
			return {
				...initialState
				, pathname: '/',
				search: '',
			};
		}
		case 'fetch': {
			//console.log('fetch', state);
			globals.isLoading = true;
			return {
				...state,
				fetchcounter: state.fetchcounter + 1,
				//url: action.url,
				isLoading: true,
				error: '',
			};
		}
		case 'drag_enter': {
			return {
				...state,
				dragging: true,
			}
		}
		case 'drag_leave': {
			return {
				...state,
				dragging: false,
			}
		}
		case 're_fetch': {
			return {
				...state,
				re_fetch: true,
			};
		}
		case 'trigger_key': {
			if (action.keycode) triggerKey(action.keycode);
			return state;
		}
		case 'json': {

			if (action.fetchcounter) {
				if (state.fetchcounter !== action.fetchcounter) {
					//	console.log('ignore json, fetchcounter wrong');
					return state;
				}
				if (state.re_fetch) {
					globals.isLoading = false;
					return {
						...state,
						isLoading: false,
						re_fetch: false,
					}
				}
			}
			let json = action.json;
			//console.log('ds JSON!',global.selectedId,json.settings);
			if (json && json.merge && state && state.json && state.json.views) {
				//	if (state.json && state.json.views && json && json.settings && json.settings === undefined && json.views) {
				//console.log('Merge JSON!',json);
				let sjson = state.json;
				if (sjson.toasts) delete sjson.toasts;
				if (json.toasts) sjson.toasts = [...json.toasts];
				//console.log(json);
				if (json.actions) sjson.actions = json.actions;
				if (json.views) sjson.views = remDupObjects([...sjson.views, ...json.views]);
				if (json.settings) sjson.settings = remDupObjects([...sjson.settings, ...json.settings]);
				if (json.querystring) sjson.querystring = remDupObjects([...sjson.querystring, ...json.querystring]);
				if (json.views)
					json.views.map(view => {
						if (view.data) {
							if (json[view.data]) sjson[view.data] = json[view.data];
							if (view.data.match(/^data/gi)) {
								let defname = view.data.replace(/^data/gi, 'def');
								if (json[defname]) sjson[defname] = json[defname];
							}
						}
					});
				json = sjson;
				/* 				console.log('json', sjson);
								return {
									...state,
									isLoading: false,
								}
				 */
			}
			//lid = state.lid;
			lidx = state.lidx;
			var edit = null//state.edit;
			//hasPicklist = false;
			origValues = state.origValues;
			search = state.search;
			pathname = state.pathname;
			let selectedId = state.selectedId;
			settings = { updatedfield: settings.updatedfield }
			//console.log('ds JSON!',global.selectedId);
			if (json.settings && json.settings.length) {
				json.settings.forEach((row) => settings[row.k] = row.v);
				settings.useHtmlNativeDatepicker = settings.useHtmlNativeDatepicker && settings.useHtmlNativeDatepicker === "1";
				//console.log(settings);
				if (settings.lidx) lidx = settings.lidx;
				//if (settings.id) global.selectedId = settings.id;
				if (settings.dateformat) setDateFormat(settings.dateformat);
				if (settings.formatDateString) setFormatDateString(settings.formatDateString);
				if (settings.formatDateTimeString) setFormatDateTimeString(settings.formatDateTimeString);
				if (settings.formatTimeString) setFormatTimeString(settings.formatTimeString);

			}
			//console.log('ds JSON!',global.selectedId);
			//console.log(json.toasts);
			state = mainReducer(state, { type: 'toasts', toasts: json.toasts });
			//let toasts = state.toasts;
			//if (json.toasts && json.toasts.length) {
			//				json.toasts.forEach((row) => toasts.push(row));
			//
			//			}
			if (json.views) {

				for (var i = 0; i < json.views.length; i++) {
					//if (json.views[i].comp === "Picklistview") hasPicklist = true;
					if (json.views[i].comp === "Detailview" && json.views[i].data === "data") {
						//console.log(json.views[i].data);
						if (Object.keys(origValues).length === 0) {
							origValues = { ...json.data[0] };
							for (var j = 0; j < json.def.length; j++) {
								let item = json.def[i]
								if (item && item.is_pl) origValues[item.name] = item.pid;
							}
							//json.def.forEach(item => {
							//	if (item.is_pl) origValues[item.name] = item.pid;
							//})
						}
					}
				}
			}
			if (json.querystring) {
				json.querystring.forEach((kvpair) => {
					if (kvpair.k === 'prev_search') {
						delete globals.selectedId
						globals.prev_search_set = kvpair.v
						return;
					}
					if (kvpair.k === 'prev_pathname') {
						globals.prev_pathname = kvpair.v
						return;
					}
					if (kvpair.k === 'pathname') {
						pathname = kvpair.v ?? ''
						if (pathname !== '' && pathname.substring(0, 1) !== '/') pathname = "/" + pathname; //gb230502

						//pathname = pathname.replace(/\/0$/,"/"+kvpair.v);
						//window.history.pushState({ ...state, selectedId: globals.selectedId }, '', pathname + search);
						window.history.pushState(state, '', pathname + search);
						return;
					}
					if (kvpair.k === 'search') {
						search = kvpair.v ?? ''
						//pathname = pathname.replace(/\/0$/,"/"+kvpair.v);
						//window.history.pushState({ ...state, selectedId: globals.selectedId }, '', pathname + search);
						window.history.pushState(state, '', pathname + search);
						return;
					}
					//if (kvpair.k === 'lid') lid = kvpair.v;
					//if (kvpair.k === 'id') selectedId = kvpair.v;
					if (kvpair.k === 'edit') edit = kvpair.v;
					var qs = {};
					qs[kvpair.k] = kvpair.v;
					search = processQueryString(search, qs);
				});
				//	console.log(search);
			}
			globals.selectedId = json.data && json.data.length === 1 && json.data[0].id ? json.data[0].id : 0
			newstate = {
				...state,
				//bearer:state
				pathname,
				cardChanges: {},
				search,
				settings,
				isLoading: false,
				showisam: false,
				//listStart:0,
				json,
				//lid,
				selectedId,
				lidx,
				edit,
				//toasts,
				error: '',
				//	hasPicklist,
				origValues,
			};
			if (state.base) window.history.replaceState(newstate, '', state.pathname + search);
			globals.isLoading = false;
			return newstate
		}
		case 'json-data-only': {
			let json = state.json
			json.data = action.data;
			let settings = state.settings;
			if (globals.selectedId) settings.id = globals.selectedId;
			return {
				...state,
				json,
				settings,
				//globals.selectedId
			}
		}
		case 'back': {
			//window.history.replaceState(state, '', state.pathname + state.search);
			window.history.back();
			return state;

		}
		case 'toasts': {
			let toasts = [] //state.toasts;
			if (action.toasts && action.toasts.length) {
				action.toasts.forEach((row) => toasts.push({ ...row, mt: row.id + window.performance.now() }));
			} else {
				return state;
			}
			//if (toasts.length > 4) toasts = [...toasts.slice(toasts.length - 4)]
			return {
				...state,
				toasts,
			}
		}
		case 'pathname': {
			//console.log('action',action);
			//window.history.replaceState(state, '', state.pathname + state.search);
			if (action.addto) {
				pathname = (action.initialPath ? action.initialPath : state.pathname) + '/' + action.addto
				pathname = pathname.replace(/[/]{2,}/gi, "/")
			} else {
				pathname = action.pathname ?? state.pathname;
			}
			if (!pathname) pathname = "/";
			//console.log('pathname',globals.selectedId);
			if (action.hasSelectedId && action.hasSelectedId === true && (globals.selectedId === null || globals.selectedId === 0)) return state;
			if (globals.selectedId === null && pathname.match(/\{selectedId\}/gi)) return state;
			pathname = pathname.replace(/\{selectedId\}/gi, globals.selectedId);
			search = action.search ? action.search : '';
			search = search.replace(/\{selectedId\}/gi, globals.selectedId);
			if (action.title) document.title = action.title;
			if (pathname.substring(0, 1) !== '/') pathname = "/" + pathname; //gb230502
			newstate = {
				...state,
				pathname,
				search,
				filter: {},
				cardChanges: {},
				//	lid: 0,
				origValues: {}
			};
			if (state.base) window.history.pushState(newstate, '', pathname + search);
			return newstate;
		}
		case 'filter_set_start': {
			if (action.clear) return { ...state, filter: {} }
			let filter = state.filter;
			let fkey = (action.key + '.' + action.expr).toLowerCase();
			if (action.val === '') {
				delete filter[fkey]
			} else {
				filter[fkey] = action.val.replace(/\)/g, '\\)');
			}
			//console.log(' state.filter', state.filter,filter)
			return { ...state, filter }
		}
		case 'filter': {
			let filter = state.filter;
			let fkey = (action.key + '.' + action.expr).toLowerCase();
			if (action.val === '') {
				delete filter[fkey]
			} else {
				filter[fkey] = action.val.replace(/\)/g, '\\)');
			}
			let filterstring = '';
			for (let k in filter) filterstring = (filterstring === '' ? '' : filterstring + ',') + k + '(' + filter[k] + ')'
			//console.log(filter);
			//console.log(filterstring);
			search = processQueryString(state.search, { filter: filterstring, off: null, ise: null });
			if (globals.setSelectedId) globals.setSelectedId(null);
			//if (globals.setHandlerId) globals.setHandlerId(null); //gb231114
			//search = processQueryString(state.search, { filter: filterstring, off: null, ise: null, lid: null });
			return { ...state, filter, search }
		}
		case 'reload': {
			return {
				...state,
				cardChangesCounter,
			}
		}
		case 'reload_client': {
			window.location.reload();
			return state
		}
		case 'search_isam': {
			search = processQueryString(state.search, { ise: action.isam, off: null, id: null });
			return {
				...state,
				search,
				cardChangesCounter,
				showisam: false,
			}

		}
		case 'post': {
			const postdata = async (action, state) => {
				let data = action.data ?? {}
				var headers = {}
				if (state.bearer) headers['Authorization'] = 'Bearer ' + state.bearer;
				headers['Content-Type'] = 'application/json';
				let url = state.base;
				url += action.pathname ?? state.pathname;
				url += action.search ?? state.search;
				if (action.sendSelectedIds && state.selectedIdsByPath[state.pathname]) {
					if (!data["api_action_post"]) data["api_action_post"] = {}
					//data["api_action_post"]['agfx-selected-ids'] = "34523454,345345"
					data["api_action_post"]['agfx-selected-ids'] = state.selectedIdsByPath[state.pathname].join();
				}
				//url += '&selectedIdsByPath=' + state.selectedIdsByPath[state.pathname].join(',');

				headers['agfx-pathname'] = action.pathname ?? state.pathname;
				headers['agfx-search'] = action.search ?? state.search;
				headers['agfx-selected-id'] = globals.selectedId ?? null;
				headers['agfx-client-uuid'] = state.clientUuid ?? null;
				headers['agfx-client-version'] = state.clientVersion ?? null;
				const response = await fetch(url, {
					method: action.method ?? 'POST',
					mode: 'cors',
					cache: 'no-cache',
					headers,
					body: action.method !== 'GET' ? JSON.stringify(data) : null,
				});
				let json;
				try {
					json = await response.json();
				} catch (e) {
					//console.log(e);
				}
				//mainReducer(state,{type:'toasts',toasts:json.toasts});
				if (action.after) action.after(json);
				//if(json.toasts) dispatch({type:'toasts',toasts:json.toasts});
			}
			postdata(action, state);
			globals.isLoading = action.isLoading ?? state.isLoading;
			return {
				...state,
				isLoading: action.isLoading ?? state.isLoading,
			};
		}
		case 'showisam': {
			return {
				...state,
				showisam: action.show
			}
		}
		case 'refresh': {
			if (settings.is_list === "1") search = processQueryString(state.search, { id: globals.selectedId });
			return {
				...state,
				search: search ?? '',
				cardChangesCounter,
			};
		}
		case 'reducer':
			//case 'state':
			{
				let qs = action.querystring ?? {};
				qs["red"] = action.name;
				qs["id"] = null;
				qs["forced_id"] = null;
				//try{qs=JSON.parse(action.querystring)}catch(e){}
				//console.log(qs);
				search = processQueryString(state.search, qs);
				return {
					...state,
					cardChangesCounter,
					search,
				};
			}
		case 'querystring': {
			let filter = state.filter
			if (action.querystring.filter === null) filter = {}
			search = processQueryString(state.search, action.querystring);
			return {
				...state,
				search,
				filter,
			};
		}
		case 'pop_state': {
			////window.history.replaceState(state, 'Title: ' + state.pathname, state.pathname + state.search);
			//	action.state.re_fetch=false;
			globals.isLoading = false;
			return {
				...action.state
				, bearer: state.bearer
				, isLoading: false
				, re_fetch: false
			};
		}
		case 'search': {
			return {
				...state,
				search: action.location.search,
			};
		}
		case 'clear_selected_ids': {
			pathname = state.pathname;
			selectedIdsByPath = state.selectedIdsByPath;
			if (selectedIdsByPath[pathname]) delete selectedIdsByPath[pathname];
			//if (globals.reloadHandler) globals.reloadHandler(true);
			return {
				...state,
				selectedIdsByPath,
			};
		}
		case 'invert_ids': {
			pathname = state.pathname;
			selectedIdsByPath = state.selectedIdsByPath;
			if (!selectedIdsByPath[pathname]) selectedIdsByPath[pathname] = [];
			action.ids.forEach(id => {
				if (selectedIdsByPath[pathname].includes(id)) {
					selectedIdsByPath[pathname].splice(selectedIdsByPath[pathname].indexOf(id, 0), 1);
				} else {
					selectedIdsByPath[pathname].push(id)
				}
			});
			//if (globals.reloadHandler) globals.reloadHandler(true);
			return {
				...state,
				selectedIdsByPath,
			};
		}
		case 'select_ids': {
			pathname = state.pathname;
			selectedIdsByPath = state.selectedIdsByPath;
			if (!selectedIdsByPath[pathname]) selectedIdsByPath[pathname] = [];
			action.ids.forEach(id => {
				if (!selectedIdsByPath[pathname].includes(id)) selectedIdsByPath[pathname].push(id);
			});
			//if (globals.reloadHandler) globals.reloadHandler(true);
			return {
				...state,
				selectedIdsByPath,
			};
		}
		case 'toggle_selected': {
			pathname = state.pathname;
			let selectRange = action.selectRange ?? false;
			selectedIdsByPath = state.selectedIdsByPath;
			if (!selectedIdsByPath[pathname]) selectedIdsByPath[pathname] = [];
			//selectedIds = state.selectedIds;
			if (selectedIdsByPath[pathname].includes(action.id)) {
				if (!selectRange) selectedIdsByPath[pathname].splice(selectedIdsByPath[pathname].indexOf(action.id, 0), 1);
			} else {
				selectedIdsByPath[pathname].push(action.id)
			}
			//if (globals.reloadHandler) globals.reloadHandler(true);
			return {
				...state,
				selectedIdsByPath,
			};
		}
		case 'lidx': {
			return {
				...state,
				lidx: action.lidx,
			};
		}
		/*		case 'save': {
					cardChanges = {};
					cardChanges["api_action_post"] = { type: "save" };
					return {
						...state,
						cardChanges,
						hasPicklist: false,
						cardChangesCounter,
					};
				}
		*/
		case 'stored_procedure': {
			//console.log('action.initialPath*', action.initialPath)
			//console.log('window.location.pathname', window.location.pathname)
			if (state.isLoading) return state;
			//console.log(action);
			if (action.initialPath && action.initialPath !== window.location.pathname) return state;
			globals.isLoading = true; //GB230803 net ff lager, we doen immers niets indien hierboven "true"

			//if (globals.selectedId > 0) return state;
			if (settings.is_list === "1") search = processQueryString(state.search, { id: globals.selectedId });
			if (settings.edit != null) search = state.search;
			//console.log(search);
			cardChanges = {};
			let api_action_post = { ...action }
			if (action && action.values && action.values.api_multipart_form) {
				globals.api_multipart_form = action.values.api_multipart_form;
				delete api_action_post.values['api_multipart_form'];
				globals.api_multipart_form.append('api_action_post', JSON.stringify({ ...api_action_post, id: globals.selectedId }))
			} else {
				let api_action_post = { ...action }
				let api_action_post_file = api_action_post && api_action_post.values && api_action_post.values['@base64data']
				if (api_action_post && api_action_post.values) delete api_action_post.values['@base64data']
				if (api_action_post_file) cardChanges["api_action_post_file"] = api_action_post_file;
				cardChanges["api_action_post"] = { ...api_action_post, id: globals.selectedId };
			}
			//console.log(search)
			return {
				...state,
				//isLoading: true,
				search: search ?? '',
				cardChanges,
				//hasPicklist: false,
				cardChangesCounter,
			};
		}
		case 'hide_modal': {
			delete state.json.data_modalview
			delete state.json.def_modalview
			let idx = null
			if (state.json.views) {
				state.json.views.forEach((view, i) => { if (view.comp === 'Modalview') idx = i })
				if (idx !== null) state.json.views.splice(state.json.views.indexOf(idx, 0), 1);
			}
			//delete state.json.views[idx]
			return state
		}
		case 'post_modal': {
			cardChanges = {};
			if (action.apiActionPost) {
				if (action.apiActionPost.api_multipart_form) {
					globals.api_multipart_form = action.apiActionPost.api_multipart_form;
					delete action.apiActionPost['api_multipart_form'];
					globals.api_multipart_form.append('api_action_post', JSON.stringify({ ...action.apiActionPost }))
				} else {
					cardChanges["api_action_post"] = action.apiActionPost;
				}
			}
			globals.isLoading = true;
			return {
				...state,
				isLoading: true,
				cardChanges,
				//hasPicklist: false,
				cardChangesCounter,
			};
		}
		case 'pre_action': {
			let preAction = action.action
			return {
				...state,
				preAction,
			};
		}
		case 'save': {
			cardChanges = {};
			if (action.key) cardChanges[action.key] = action.val ?? null;
			if (action.save || state.preAction === 'save') cardChanges["api_action_post"] = { type: "save" };
			return {
				...state,
				cardChanges,
				preAction: null,
				//hasPicklist: false,
				cardChangesCounter,
			};
		}
		case 'set_selected_id': {
			//settings = state.settings;
			settings.id = action.id;
			if (globals.selectedId === action.id) {
				//globals.setSelectedId(action.id);
				triggerKey("Enter");
			} else {
				//globals.setSelectedId(action.id);
			}
			return {
				...state,
				settings,
			};

		}
		case 'set_setting': {
			settings[action.key] = action.value;
			return {
				...state,
				settings,
			};
		}
		case 'select_from_list': {
			if (!(globals.selectedId > 0) || state.isLoading) return state;
			//console.log('isLoading',state.isLoading)
			pathname = (action.pathname ? action.pathname : state.pathname) + '/' + globals.selectedId;
			pathname = pathname.replace(/[/]{2,}/gi, "/")
			return {
				...state,
				search: state.search === '?edit=' ? '?edit=' : '',
				pathname,
				cardChangesCounter,
			};
		}
		case 'excel': {
			let { seperator, extension, top } = action;
			if (!seperator) seperator = ';'
			if (!extension) extension = 'csv'
			if (!top) top = 100000
			//console.log(seperator,extension);
			var headers = {}
			if (state.bearer) headers['Authorization'] = 'Bearer ' + state.bearer;
			let url = state.base;
			url += state.pathname;
			url += processQueryString(state.search, { top: top });
			fetch(url, {
				method: 'GET',
				mode: 'cors',
				cache: 'no-cache',
				headers,

			}).then(res => res.json()).then(json => {
				window.URL = window.URL || window.webkiURL;
				let raw = '';
				if (json.data.length) {
					let head = Object.keys(json.data[0]).map(v => {
						if (!v) return '""'
						v = v + '';
						return '"' + (v ? v.replace(/"/gi, '""') : '') + '"'
					}).join(seperator) + "\r\n"
					raw = head + json.data.map(item => {
						return Object.values(item).map(v => {
							if (!v) return '""'
							v = v + '';
							return '"' + (v ? v.replace(/"/gi, '""') : '') + '"'
						}).join(seperator)
					}).join("\r\n")
				}
				//let blob = new Blob();
				//let blobURL = window.URL.createObjectURL(blob);

				let a = document.createElement('a');
				let universalBOM = "\uFEFF";
				a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + raw));
				a.setAttribute('download', (state.pathname.replace(/[^a-z0-9]/gi, "-") + "." + extension).substring(1).toLowerCase());
				//a.href = blobURL;
				a.click();

			})
			return state;
		}
		case 'print': {
			if (settings.printname) {
				var tempTitle = document.title;
				document.title = settings.printname;
				window.print();
				document.title = tempTitle;
			} else {
				window.print();

			}
			let { params } = action;
			if (params && params.escape) triggerKey("Escape")
			return state;
		}
		case 'download': {
			let { filename, mimetype, rawdata } = action;
			if (!filename) filename = 'download.txt';
			if (!mimetype) mimetype = 'text/csv';
			if (!rawdata) rawdata = '';
			let a = document.createElement('a');
			let universalBOM = "\uFEFF";
			a.setAttribute('href', 'data:' + mimetype + ';base64,' + rawdata);
			a.setAttribute('download', filename);
			a.click();
			return state;
		}
		case 'field_insert': {
			let { fid, text } = action;
			let fidElem = document.querySelector("[fid=\"" + fid + "\"] textarea,[fid=\"" + fid + "\"] input");
			if (fidElem) {
				fidElem.focus()
				let s = fidElem.selectionStart
				document.execCommand('insertText', false, text);
				setTimeout(() => { fidElem.setSelectionRange(s + text.length, s + text.length) }, 200)
			}
			return state;
		}
		case 'screenshot': {
			setTimeout(() => { screenshot(action) }, 200);
			return state;

		}
		case 'open_url': {
			let { url } = action
			if (url) {
				window.open(url);
				/*var element = document.createElement('a');
				element.style.display = 'none';
				element.setAttribute('href', url);
				element.setAttribute('target', '_blank');
				element.setAttribute('download',null);
				document.body.appendChild(element);
				//element.dispatchEvent(new MouseEvent("click", {ctrlKey: true}));
				//setTimeout(() => { element.click(); }, 500)
				element.click();
				document.body.removeChild(element);
				*/
			}
			return state;
		}
		case 'open_url_in_new_tab': {
			let { url, replace, windowFeatures } = action
			//console.log(url, replace, windowFeatures);
			if (url) {
				let data = state.json.data ? (state.json.data[0] ?? false) : false; //GB240418 in het hoofdmenu is data leeg!
				//console.log(data);
				if (replace && data) Object.keys(replace).forEach(key => {
					let field = replace[key];
					if (field === "currentField") {
						//console.log(state);
						let idx = state.json.def.findIndex(x => x.fid === settings.updatedfield) ?? 0;
						field = state.json.def[idx].name ?? field;
					}
					if (data[field]) url = url.replace(key, data[field]);
				});
				window.open(url, '_blank', windowFeatures);
			}
			return state;
		}
		case 'rename': {
			let rename = { ...action };
			//console.log(rename)
			//if (rename.event) rename.event.preventDefault();
			if (!rename.name) rename = null;
			if (rename && !rename.context) rename.context = settings.cardname;
			return {
				...state,
				rename: rename,
			}
		}
		case 'pick_from_list': {
			if (!(globals.selectedId > 0)) return state;
			cardChanges = {};
			pathname = action.pathname;
			cardChanges[action.key] = globals.selectedId;
			return {
				...state,
				cardChanges,
				search: action.search ?? state.search,
				pathname,
				cardChangesCounter,
			};
		}
		case 'reset_all_form_fields': {
			return {
				...state,
				cardChanges: { reset_all_form_fields: true },
				cardChangesCounter,
			};
		}
		case 'reset_custom_form_field': {
			return {
				...state,
				cardChanges: { reset_custom_form_field: action.key },
				cardChangesCounter,
			};
		}
		/*case 'pagedown': {
			if (state.listStart > 0 || state.json.data.length < state.listLength) return state;
			return {
				...state,
				listStart: state.listLength
			}
		}
		*/
		default:
			alert('No action type:  ' + action.type);
			return state;

	}
}

let initialState = {
	base: '',
	bearer: false,
	cardChanges: {},
	cardChangesCounter: 0,
	dragging: false,
	edit: null,
	error: '',
	fetchcounter: 0,
	ise: '',
	filter: {},
	isLoading: false,
	isLoggedIn: false,
	json: {},
	clientUuid: uuidv4(),
	clientVersion: "240812.1",
	//lid: 0,
	//listStart: 0,
	//listLength: 15,
	lidx: false,
	origValues: {},
	pathname: window.location.pathname,
	preAction: null,
	search: window.location.search,
	re_fetch: false,
	selectedId: 0,
	selectedIds: [],
	selectedIdsByPath: {},
	settings: {},
	showisam: false,
	toasts: [],

	ismobile: 'ontouchstart' in window || navigator.msMaxTouchPoints,
};

